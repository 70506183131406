<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import { BaseInputText, BaseButton, BaseSelect } from '@edsights/ui-core';
import ButtonBack from '@/components-deprecated/ButtonBack.vue';
import { isWebChatbotEnabled, isWebChatbotSettingsPageEnabled } from '@/lib/feature-flag';
import { findById, updateSchoolWebChatbotSettings } from '@/api/school';
import Alerts from '@/lib/alerts';
import { WEB_CHATBOT_SCREEN_POSITION, WEB_CHATBOT_DEFAULT_MASCOT_URL } from './constants.js';
const webChatbotScreenPositionOptions = Object.values(WEB_CHATBOT_SCREEN_POSITION);
import MascotImage from './components/MascotImage.vue';
const store = useStore();
const isLoading = ref(false);
const isSaving = ref(false);
const uploadedMascotImageDataUrl = ref(null);
const isStaff = computed(() => {
    return store.state.user.isStaff;
});
// The settings are set to some defaults.
// Once the school's settings are loaded, this will get updated with their customizations
const defaultSettings = {
    color: '#038BFF',
    webChatbotScreenPosition: WEB_CHATBOT_SCREEN_POSITION.BOTTOM_RIGHT.value,
    mascotUrl: WEB_CHATBOT_DEFAULT_MASCOT_URL,
    webChatbotMascotWidthPixels: 40,
    webChatbotMascotHeightPixels: 40
};
const settings = ref({
    color: defaultSettings.color,
    webChatbotScreenPosition: defaultSettings.webChatbotScreenPosition,
    mascotUrl: defaultSettings.mascotUrl,
    webChatbotMascotWidthPixels: defaultSettings.webChatbotMascotWidthPixels,
    webChatbotMascotHeightPixels: defaultSettings.webChatbotMascotHeightPixels
});
async function save() {
    try {
        isSaving.value = true;
        await updateSchoolWebChatbotSettings({
            schoolId: store.state.user.schoolRef.id,
            mascotImageDataUrl: uploadedMascotImageDataUrl.value,
            color: settings.value.color || defaultSettings.color,
            webChatbotMascotWidthPixels: (
                settings.value.webChatbotMascotWidthPixels ||
                defaultSettings.webChatbotMascotWidthPixels
            ).toString(),
            webChatbotMascotHeightPixels: (
                settings.value.webChatbotMascotHeightPixels ||
                defaultSettings.webChatbotMascotHeightPixels
            ).toString(),
            webChatbotScreenPosition:
                settings.value.webChatbotScreenPosition || defaultSettings.webChatbotScreenPosition,
            errorHandlerOptions: {
                rethrow: true,
                showAlert: true,
                overrideMessage: 'Error saving web chatbot settings.'
            }
        });
        Alerts.alert({
            type: 'success',
            message: 'Settings saved successfully.'
        });
    } finally {
        isSaving.value = false;
    }
}

async function loadSchoolWebChatbotSettings() {
    const school = await findById(store.state.user.schoolRef.id, true);

    if (school.color) {
        settings.value.color = school.color;
    }

    if (school.webChatbotScreenPosition) {
        settings.value.webChatbotScreenPosition = school.webChatbotScreenPosition;
    }

    // The width and height school settings are saved as strings, but we're using them as numbers
    // for easier validation.
    if (school.webChatbotMascotWidthPixels) {
        settings.value.webChatbotMascotWidthPixels = Number(school.webChatbotMascotWidthPixels);
    }

    if (school.webChatbotMascotHeightPixels) {
        settings.value.webChatbotMascotHeightPixels = Number(school.webChatbotMascotHeightPixels);
    }

    if (school.mascotUrl) {
        settings.value.mascotUrl = school.mascotUrl;
    }
}

// Since this page can be navigated to directly, we must check if it should show:
const showPage = ref(false);
onMounted(async () => {
    try {
        isLoading.value = true;
        const webChatbotEnabled = await isWebChatbotEnabled(store.state.user.schoolRef.id);
        const settingsPageEnabled = webChatbotEnabled
            ? await isWebChatbotSettingsPageEnabled(store.state.user.schoolRef.id)
            : false;

        if (webChatbotEnabled && settingsPageEnabled) {
            showPage.value = true;
            await loadSchoolWebChatbotSettings();
        }
    } finally {
        isLoading.value = false;
    }
});
</script>

<template>
    <div class="manage-web-chatbot container">
        <ButtonBack />

        <LoadingSpinner v-if="isLoading" />
        <div v-if="showPage">
            <div class="box">
                <h1 class="h2">Web Chatbot Settings</h1>
                <p>
                    Below are the web chatbot configuration settings. This page is "view only" so if
                    you need to make edits, reach out to your EdSights point of contact.
                </p>

                <section class="section-container">
                    <h2 class="h3">Mascot Image</h2>

                    <div class="row-container">
                        <MascotImage
                            :isStaff="isStaff"
                            v-model:uploadedMascotImageDataUrl="uploadedMascotImageDataUrl"
                            :mascotUrl="settings.mascotUrl"
                            v-model:webChatbotMascotWidthPixels="
                                settings.webChatbotMascotWidthPixels
                            "
                            v-model:webChatbotMascotHeightPixels="
                                settings.webChatbotMascotHeightPixels
                            "
                            :defaultSettings="defaultSettings"
                        />
                    </div>
                </section>
                <section class="section-container">
                    <h2 class="h3">Color</h2>

                    <div class="row-container">
                        <div class="color">
                            <BaseInputText
                                :readOnly="!isStaff"
                                v-model.trim="settings.color"
                                label="Primary Color"
                                :placeholder="defaultSettings.color"
                            />
                            <div
                                class="color-preview"
                                :style="{ backgroundColor: settings.color }"
                            />
                        </div>
                    </div>
                </section>
                <section class="section-container">
                    <h2 class="h3">Positioning</h2>
                    <div class="row-container">
                        <BaseSelect
                            :readOnly="!isStaff"
                            v-model="settings.webChatbotScreenPosition"
                            label="Position on Website"
                            :options="webChatbotScreenPositionOptions"
                        />
                    </div>
                </section>
                <div v-if="isStaff" class="button-container">
                    <BaseButton @click="save" label="Save" :loading="isSaving" />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.manage-web-chatbot {
    // todo: ui-core needs this style on labels
    // adding here for now
    :deep(label) {
        font-weight: bold;
    }
    .color {
        display: flex;
        align-items: end;
        .color-preview {
            margin-left: 10px;
            border-radius: 5px;
            width: 3.16rem;
            height: 3.16rem;
        }
    }
    .button-container {
        display: flex;
        justify-content: flex-end;
    }
}
.section-container {
    margin-top: 25px;
    margin-left: 25px;
}

.row-container {
    display: flex;
    justify-content: space-between;
    & > * {
        margin-top: 20px;
        width: 40%;
        max-width: 40%;
    }
}
.h3 {
    color: black;
    font-size: initial;
}
</style>
