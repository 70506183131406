<template>
    <modal
        name="bulk-retire-users"
        height="auto"
        :clickToClose="false"
        :stack="false"
        tabIndex="-1"
    >
        <div class="modal-container">
            <div class="modal-body">
                <div class="container">
                    <h2>Step 1: Select a school</h2>
                    <div class="school-dropdown">
                        <v-select
                            :options="schools"
                            v-model="selectedSchool"
                            label="name"
                            placeholder="Search for a school..."
                            :clearable="false"
                        />
                    </div>
                </div>
                <div class="container upload">
                    <h2>Step 2: Upload a CSV with user emails</h2>
                    <div class="include-admins">
                        <Checkbox
                            v-model="includeAdmins"
                            label="Include Admins"
                            :isSmall="true"
                        />
                    </div>
                    <div class="select-upload">
                        <ChooseFileCancelable
                            :readAsString="false"
                            @loaded="onFileLoaded"
                            :disabled="!selectedSchool.id"
                        >
                            <template v-slot:message>
                                <h3 class="upload-message">Upload your Spreadsheet</h3>
                            </template>
                        </ChooseFileCancelable>
                    </div>
                    <div class="upload-status" v-if="!fileLoading">
                        <h2>
                            CSV contains 
                            <span class="user-count">{{ this.users.length }} emails</span>.
                        </h2>
                    </div>
                    <div class="upload-status" v-else>
                        <LoadingSpinner />
                    </div>
                    <div class="email-note">Email count may differ from retired user count.</div>
                </div>
            </div>
            <div class="non-retired-emails-container">
                <div class="non-retired-emails-section" v-if="showNonRetiredEmailsSection">
                    <h3 class="toggle-header">
                        <details>
                            <summary style="cursor: pointer;" @click="toggleNonRetiredEmails">
                                Non Retired Emails ({{ nonRetiredEmails.length }})
                            </summary>
                        </details>
                        <span class="download-file" @click="downloadNonRetiredEmailsList">
                            Download List
                        </span>
                    </h3>
                    <div class="emails-list" v-if="showNonRetiredEmailsList">
                        <ul>
                            <li v-for="email in nonRetiredEmails" :key="email">{{ email }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="footer">
                <Button styleType="secondary" class="close-btn" @click="onClose">Close</Button>
                <loading-spinner-button
                    class="save-btn"
                    text="Retire Users"
                    :loading="loading"
                    @click="onSubmitBulk"
                    :disabled="!selectedSchool.id || !users.length"
                />
            </div>
        </div>
    </modal>
</template>

<script>
import ChooseFileCancelable from '@/components-deprecated/ChooseFileCancelable';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton.vue';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Button from '@/components-deprecated/global/Button.vue';
import Checkbox from '@/components-deprecated/inputs/Checkbox.vue';
import * as SchoolApi from '@/api/school';
import * as UserApi from '@/api/user';

export default {
    name: 'BulkRetireUsersModal',
    components: {
        ChooseFileCancelable,
        LoadingSpinnerButton,
        LoadingSpinner,
        Button,
        Checkbox
    },
    data() {
        return {
            loading: false,
            fileLoading: false,
            schools: [],
            users: [],
            selectedSchool: {},
            includeAdmins: false,
            nonRetiredEmails: [],
            showNonRetiredEmailsList: false,
            showNonRetiredEmailsSection: false
        };
    },
    created() {
        this.loadSchools();
    },
    methods: {
        onClose() {
            this.resetFields();
            this.$modal.hide('bulk-retire-users');
        },
        resetFields() {
            this.selectedSchool = '';
            this.users = [];
            this.nonRetiredEmails = [];
            this.showNonRetiredEmailsSection = false;
            this.showNonRetiredEmailsList = false;
            this.includeAdmins = false;
        },
        async loadSchools() {
            this.schools = (
                await SchoolApi.listSchools({ queryParams: { pageSize: 500, isActive: true } })
            ).results;
        },
        async onFileLoaded(file) {
            this.fileLoading = true;
            if (!file) {
                this.users = [];
                this.fileLoading = false;
                return;
            }
        
            const reader = new FileReader();
            reader.onload = (e) => {
                const csv = e.target.result;
                const lines = csv.split(/\r?\n/);
            
                if (lines.length === 0) {
                    this.$Alert.alert({
                        type: 'error',
                        message: 'CSV file is empty.',
                        timeout: 5000
                    });
                    this.fileLoading = false;
                    return;
                }
            
                const headers = lines[0].split(',').map(header => header.toLowerCase().trim());
                const emailIndex = headers.findIndex(header => header === 'email');
            
                if (emailIndex === -1) {
                    this.$Alert.alert({
                        type: 'error',
                        message: 'CSV file must contain an "email" column.',
                        timeout: 5000
                    });
                    this.fileLoading = false;
                    return;
                }
            
                const emails = lines.slice(1)
                    .map(line => {
                        const columns = line.split(',');
                        return columns[emailIndex] ? columns[emailIndex].trim() : null;
                    })
                    .filter(email => email !== null && email !== undefined);
                
                this.users = emails;
                this.fileLoading = false;
            };
        
            reader.onerror = () => {
                this.$Alert.alert({
                    type: 'error',
                    message: 'Failed to read the file.',
                    timeout: 5000
                });
                this.fileLoading = false;
            };
        
            reader.readAsText(file);
        },
        async onSubmitBulk() {
            this.loading = true;
            if (this.users.length === 0) {
                this.$Alert.alert({
                    type: 'error',
                    message: `Whoops. User list is empty.`,
                    timeout: 5000
                });
                this.loading = false;
                return;
            }

            const response = await UserApi.retireUsers({
                userEmails: this.users,
                schoolId: this.selectedSchool.id,
                includeAdmins: this.includeAdmins,
            });

            if (response) {
                this.$Alert.alert({
                    type: response.partialRetire
                        ? 'warning'
                        : 'success',
                    message: response.message,
                    timeout: 8000
                });

                // on a partial retire, display the list of users that were not retired
                // otherwise close the modal
                if (response.partialRetire) {
                    this.nonRetiredEmails = response.nonRetiredEmails;
                    this.showNonRetiredEmailsSection = true;
                } else {
                    this.onClose();
                }
            }
            this.loading = false;
        },
        toggleNonRetiredEmails() {
            this.showNonRetiredEmailsList = !this.showNonRetiredEmailsList;
        },
        downloadNonRetiredEmailsList() {
            const header = 'email\n';
            const csvContent = header + this.nonRetiredEmails.join('\n');

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

            const link = document.createElement('a');
            if (link.download !== undefined) {
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', 'non_retired_emails.csv');
            
              document.body.appendChild(link);
            
              link.click();
            
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins/buttons';

.modal-container {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 500px;
    max-height: 90vh;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 3rem;
    overflow-y: auto;
    flex: 1 1 auto;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #E3EDED;
    box-shadow: 0 5px 5px 0 #F6F9FE;
    border-radius: 6px;
    width: 90%;
    padding: 1.5rem;
    box-sizing: border-box;
}

.school-dropdown {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 30rem;
}

.select-upload {
    margin-top: 1rem;
    margin-right: 2.5rem;
}

.footer {
    margin-top: auto; 
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0 1rem 0;
}

.save-btn {
    @include base-button();
}

.close-btn {
    margin-right: 1rem;
}

.user-count {
    color: $edsights-blue;
}

.include-admins {
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.non-retired-emails-section {
    width: 90%;
    border: 1px solid #E3EDED;
    box-shadow: 0 5px 5px 0 #F6F9FE;
    border-radius: 6px;
}

.toggle-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    user-select: none;
}

.emails-list {
    max-height: 350px;
    overflow-y: auto;
    padding: 0 1rem;
}

.emails-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.emails-list li {
    padding: 0.5rem 0;
    border-bottom: 1px solid #f0f0f0;
}

.upload-status {
    margin-top: 3rem;
    text-align: center;
}

.email-note {
    margin-top: 1rem;
    text-align: center;
    color: #666;
}

.non-retired-emails-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.download-file {
    cursor: pointer;
    color: #0d6ec4;
    text-decoration: underline;
}
</style>
