<template>
    <div class="dashboard-container">
        <div class="search-container">
            <h2 style="margin-bottom: 1rem;">Search for a user email to retire</h2>
            <div class="checkbox-container">
                <div class="checkbox-detail">
                    <span>Admins</span>
                    <Checkbox
                        :modelValue="includeAdmin"
                        @update:modelValue="handleCheckboxClick('admin', $event)"
                    />
                </div>
                <div class="checkbox-detail">
                    <span>Students</span>
                    <Checkbox
                        :modelValue="includeStudents"
                        @update:modelValue="handleCheckboxClick('student', $event)"
                    />
                </div>
                <div class="checkbox-detail">
                    <span>Include Inactive</span>
                    <Checkbox
                        :modelValue="includeInactive"
                        @update:modelValue="handleCheckboxClick('includeInactive', $event)"
                    />
                </div>
            </div>
            <input
                class="input-box"
                v-model="matchEmail"
                placeholder="johndoe@monsters.edu"
            />
            <Button
                class="button-search"
                width="30rem"
                @click="submitSearchUserHandler"
                v-if="!showSearchLoadingSpinner"
            >
                Submit search
            </Button>
            <div v-else>
                <LoadingSpinner />
            </div>
            <BulkRetireUsersModal />
            <div style="color: #38a0fa;">
                <span style="cursor: pointer;" @click="bulkRetireUsersHandler">
                    Click here to retire users in bulk
                </span>
            </div>
        </div>
        <div class="results-container">
            <div class="search-results">
                <h2>{{ users.length === 1 ? 'User details' : 'Search results' }}</h2>
                <div class="user-dropdown">
                    <v-select
                        :options="users"
                        v-model="selectedUser"
                        label="email"
                        @update:modelValue="userSelectionHandler"
                        placeholder="Select a user"
                        :clearable="false"
                        :disabled="users.length <= 1"
                        v-if="showResultSearchBox"
                    />
                </div>
            </div>
            <div class="table-container">
                <div class="table-header" v-if="selectedUser">
                        <div class="table-header-small">Name</div>
                        <div class="table-header-small">User Type</div>
                </div>
                <div class="user-detail-container">
                    <div class="user-detail-cell-small" v-if="selectedUser">
                        {{ selectedUser.firstName }} {{ selectedUser.lastName }}
                    </div>
                    <div class="user-detail-cell-small" v-if="selectedUser">
                        {{ displayUserType(selectedUser) }}
                    </div>
                </div>
            </div>
            <div>
                <Button
                    class="button-retire"
                    width="30rem"
                    @click="showConfirmationModalHandler"
                    v-if="!showRetireLoadingSpinner"
                    :disabled="!selectedUser"
                >
                    Retire user
                </Button>
                <div v-else>
                    <LoadingSpinner />
                </div>
            </div>
            <Modal
                v-if="showConfirmationModal"
                title="Confirm Changes"
                size="medium"
                primaryButtonTitle="Confirm"
                @secondary-button-clicked="showConfirmationModal = false"
                @primary-button-clicked="() => retireUserHandler()"
            >
                <div>
                    {{ message }}
                </div>
            </Modal>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import * as UserApi from '@/api/user';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Modal from '@/components-deprecated/global/Modal';
import Button from '@/components-deprecated/global/Button.vue';
import Checkbox from '@/components-deprecated/global/Checkbox';
import BulkRetireUsersModal from './BulkRetireUsersModal.vue';

export default {
    name: 'RetireUser',
    components: {
        LoadingSpinner,
        vSelect,
        Modal,
        Button,
        Checkbox,
        BulkRetireUsersModal
    },
    data() {
        return {
            showSearchLoadingSpinner: false,
            showRetireLoadingSpinner: false,
            users: [],
            selectedUser: '',
            successLog: '',
            showConfirmationModal: false,
            message: '',
            matchEmail: '',
            includeStaff: false,
            includeAdmin: true,
            includeStudents: false,
            includeInactive: false,
            showResultSearchBox: true
        };
    },
    methods: {
        resetUserFields() {
            this.selectedUser = '';
            this.users = [];
        },
        displayUserType(user) {
            if (user.isAdmin) {
                return 'Admin';
            }
            return 'Student';
        },
        buildQueryParams() {
            return {
                matchEmail: this.matchEmail,
                includeAdmin: this.includeAdmin,
                includeStudents: this.includeStudents,
                includeInactive: this.includeInactive
            };
        },
        handleCheckboxClick(filterType, value) {
            switch (filterType) {
                case 'admin':
                    this.includeAdmin = value;
                    break;
                case 'includeInactive':
                    this.includeInactive = value;
                    break;
                default:
                    this.includeStudents = value;
                    break;
            };
        },
        processSearchResults(response) {
            if (Array.isArray(response)) {
                // display the select user list
                if (response.length > 1) {
                    this.$Alert.alert({
                        type: 'success',
                        message: `Search returned ${response.length} results. Please select a user from the dropdown.`,
                        timeout: 5000
                    });
                    this.users = response;
                    this.showResultSearchBox = true;
                }
                // don't display select user list, only the user details
                if (response.length === 1) {
                    this.$Alert.alert({
                        type: 'success',
                        message: 'User found!',
                        timeout: 3000
                    });
                    this.showResultSearchBox = false;
                    this.users[0] = response[0];
                    this.selectedUser = response[0];
                }
                if (response.length === 0) {
                    this.$Alert.alert({
                        type: 'error',
                        message: 'Whoops. Could not find a user with that email.',
                        timeout: 3000
                    });
                }
            }
        },
        async submitSearchUserHandler() {
            try {
                this.showSearchLoadingSpinner = true;
                this.resetUserFields();

                // help minimize strain on the database by imposing a min char amount
                if (this.matchEmail.length < 5) {
                    throw 'Whoops. Email must be a minimum of 5 characters';
                }

                // don't allow a search to go through if a user type isn't selected
                // this is also enforced in the api but putting a check here saves a trip
                if (!this.includeAdmin && !this.includeStudents) {
                    throw 'Whoops. Please choose at least 1 user type';
                }
                
                const params = this.buildQueryParams();
                const response = await UserApi.listUsersByEmail({ params });
                if (!response) {
                    throw 'Whoops. Something went wrong.';
                }

                this.processSearchResults(response);
            } catch (error) {
                console.error(error);
                this.$Alert.alert({
                        type: 'error',
                        message: error,
                        timeout: 3000
                    });
            } finally {
                this.showSearchLoadingSpinner = false;
            }
        },
        userSelectionHandler(user) {
            this.selectedUser = user;
        },
        showConfirmationModalHandler() {
            if (!this.selectedUser) {
                this.$Alert.alert({
                    type: 'warn',
                    message: 'Please select a user...',
                    timeout: 3000
                });
            }
            if (this.selectedUser) {
                this.message = `Are you sure you want to retire ${this.selectedUser.email}?`;
                this.showConfirmationModal = true;
            }
        },
        async retireUserHandler() {
            this.showLoadingSpinner = true;
            const response = await UserApi.retireUsers({
                userEmails: [this.selectedUser.email],
                schoolId: this.selectedUser.schoolId,
                includeAdmins: this.includeAdmin,
                errorHandlerOptions: {
                    overrideMessage: 'Whoops. There was a problem retiring this user.'
                }
            });

            if (response) {
                this.$Alert.alert({
                    type: 'success',
                    message: response.message,
                    timeout: 5000
                });
                // "refresh" the user list without refreshing whole page
                this.users = this.users.filter((user) => user.id !== this.selectedUser.id);
                this.selectedUser = '';
            }

            this.showConfirmationModal = false;
            this.showLoadingSpinner = false;
        },
        bulkRetireUsersHandler() {
            this.$modal.show('bulk-retire-users');
        }
    }
};
</script>

<style lang="scss" scoped>
.dashboard-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 2rem;
}
.search-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #E3EDED;
    box-shadow: 0 5px 5px 0 #F6F9FE;
    border-radius: 6px;
    padding: 25px 20px;
    width: 50rem;
    height: 28rem;
}
.search-container:hover {
    box-shadow: 0 0 5px 0 #068BFF;
}
.input-box {
    height: 27px;
    width: 30rem;
    padding-left: 10px;
    border: 1px solid #3c3c3c42;
    border-radius: 3px;
    margin: 1rem 0;
}
.checkbox-container {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
}
.checkbox-detail {
    display: flex;
    gap: 5px;
}
.button-search {
    margin: 1rem 0;
}
.results-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #E3EDED;
    box-shadow: 0 5px 5px 0 #F6F9FE;
    border-radius: 6px;
    padding: 25px 20px;
    width: 50rem;
    height: 28rem;
}
.results-container:hover {
    box-shadow: 0 0 5px 0 #068BFF;
}
.search-results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.user-dropdown {
    margin-top: 1rem;
    width: 30rem;
}
.table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.table-header {
    display: flex;
    padding: 5px 10px;
    background-color: #f4f7f9;
    align-items: center;
    justify-content: space-evenly;
    font-weight: bold;
    width: 40rem;
    margin-top: 1rem;
}
.table-header-small {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-detail-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 5px 10px;
    width: 45rem;
    margin-bottom: 1rem;
}
.user-detail-cell-small {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
