<template>
    <div class="templates-table">
        <div class="chips-wrapper" v-if="filterChips.length">
            <Chips :chips="filterChips" @chip-clicked="removeFilterChip" showCloseIcon />
        </div>
        <div class="templates-toolbar">
            <div class="filters">
                <TextInput
                    class="search"
                    placeholder="Search Templates"
                    iconType="search"
                    v-model="filters.search"
                    debounce
                />
                <Dropdown
                    class="label-filter"
                    label="Filter by Label"
                    :options="labelFilterOptions"
                    :activeOptions="filters.labels"
                    @option-clicked="toggleLabelFilter"
                    enableSearch
                />
            </div>
            <div class="buttons">
                <ButtonDropdown
                    styleType="secondary"
                    :options="actionOptions"
                    :disabled="!this.selectedRowsCount"
                    @option-clicked="this.onRowActionClick"
                    >Actions</ButtonDropdown
                >
                <Button @click="$emit('createNew')">
                    New Template
                </Button>
            </div>
        </div>

        <Table
            v-if="tableData"
            :data="tableData"
            class="templates"
            @next-page="$emit('nextPage')"
            :hasNextPage="hasNextPage"
            :nextPageLoading="nextPageLoading"
        >
            <template v-slot:header>
                <tr>
                    <th scope="col">
                        <Checkbox
                            :modelValue="allRowsSelected"
                            @update:modelValue="toggleAllRowsSelected"
                        />
                    </th>
                    <th scope="col">
                        Template Name
                    </th>
                    <th scope="col">
                        Labels
                    </th>
                </tr>
            </template>
            <template v-slot:rows="{ rows }">
                <tr v-for="row in rows" class="row" :key="`row-${row.id}`">
                    <td class="checkbox">
                        <div class="content">
                            <Checkbox v-model="selectedRowsMap[row.id]" />
                        </div>
                    </td>
                    <td class="name">
                        <div class="content">
                            <h3>
                                {{ row.name }}
                            </h3>
                            <div class="tooltip-container">
                                <img
                                    v-tooltip="row.touchpointDescription"
                                    class="tooltip-image"
                                    src="@/assets/icons/question-mark.svg"
                                    alt="Question mark"
                                    tabindex="0"
                                />
                            </div>
                        </div>
                    </td>
                    <td class="labels">
                        <div class="content">
                            <Chips :chips="getRowLabelChips(row)" />
                        </div>
                    </td>
                </tr>
            </template>
        </Table>
        <EmptyState
            v-if="pagedTemplates && pagedTemplates.count === 0"
            message="No results."
            sub-message="Try another search."
        />
    </div>
</template>

<script>
import Table from '@/components-deprecated/global/Table';
import TextInput from '@/components-deprecated/global/TextInput';
import Dropdown from '@/components-deprecated/global/Dropdown';
import Chips from '@/components-deprecated/global/Chips';
import EmptyState from '@/components-deprecated/global/EmptyState';
import Checkbox from '@/components-deprecated/global/Checkbox';
import Button from '@/components-deprecated/global/Button';
import ButtonDropdown from '@/components-deprecated/global/ButtonDropdown';

export default {
    name: 'TemplatesTable',
    components: {
        EmptyState,
        Chips,
        TextInput,
        Table,
        Button,
        Dropdown,
        Checkbox,
        ButtonDropdown
    },
    props: {
        pagedTemplates: {
            type: Object,
            required: true
        },
        labels: {
            type: Array,
            required: true,
            validator: function(labels) {
                return (
                    Array.isArray(labels) &&
                    labels.every(l => typeof l.text === 'string' && typeof l.id === 'string')
                );
            }
        },
        nextPageLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            filters: {
                search: '',
                labels: []
            },
            selectedRowsMap: {},
            allRowsSelected: false,
            actions: {
                EDIT: {
                    label: 'Edit Template',
                    value: 'edit'
                },
                DELETE: {
                    label: 'Delete',
                    value: 'delete'
                },
                ADD_LABEL: {
                    label: 'Add Label',
                    value: 'add-label'
                },
                ASSIGN_TO_SCHOOL: {
                    label: 'Assign to School',
                    value: 'assign-to-school'
                }
            }
        };
    },
    methods: {
        removeFilterChip(chip) {
            const { filterType, value } = chip;
            if (filterType === 'label') {
                this.toggleLabelFilter(value);
            }
        },
        toggleLabelFilter(labelId) {
            if (this.filters.labels.includes(labelId)) {
                this.filters.labels = this.filters.labels.filter(l => l !== labelId);
            } else {
                this.filters.labels.push(labelId);
            }
        },
        getRowLabelChips(row) {
            if (typeof row === 'object' && Array.isArray(row.labels)) {
                return row.labels.reduce((acc, label) => {
                    const { text, id } = label;
                    if (typeof text === 'string' && typeof id === 'string') {
                        acc.push({ label: text, value: id });
                    }

                    return acc;
                }, []);
            }

            return [];
        },
        toggleAllRowsSelected(value) {
            this.allRowsSelected = value;
            Object.keys(this.selectedRowsMap).forEach(rowId => {
                this.selectedRowsMap[rowId] = value;
            });
        },
        onRowActionClick(action) {
            const selectedRowIds = this.selectedRowIds;
            if (!Array.isArray(selectedRowIds)) {
                return;
            }
            switch (action) {
                case this.actions.EDIT.value:
                    if (selectedRowIds.length === 1) {
                        this.$emit('editTemplate', selectedRowIds[0]);
                    }
                    break;
                case this.actions.DELETE.value:
                    this.$emit('deleteTemplates', selectedRowIds);
                    break;
                case this.actions.ADD_LABEL.value:
                    this.$emit('addLabelToTemplates', selectedRowIds);
                    break;
                case this.actions.ASSIGN_TO_SCHOOL.value:
                    this.$emit('assignTemplatesToSchool', selectedRowIds);
                    break;
            }
        },
        clearSelectedRows() {
            // note: this function is called from the parent component via a ref
            if (typeof this.selectedRowsMap === 'object') {
                const newSelectedRowsMap = {};
                Object.keys(this.selectedRowsMap).forEach(rowId => {
                    newSelectedRowsMap[rowId] = false;
                });
                this.selectedRowsMap = newSelectedRowsMap;
            }
        }
    },
    computed: {
        filterChips() {
            const chips = [];

            this.filters.labels.forEach(labelId => {
                const label = this.labels.find(l => l.id === labelId);
                const { id, text } = label;
                chips.push({ label: `Label: ${text}`, value: id, filterType: 'label' });
            });

            return chips;
        },
        labelFilterOptions() {
            if (this.labels && Array.isArray(this.labels)) {
                return this.labels.map(l => ({ value: l.id, label: l.text }));
            }
            return [];
        },
        tableData() {
            if (
                typeof this.pagedTemplates === 'object' &&
                Array.isArray(this.pagedTemplates.results)
            ) {
                return this.pagedTemplates.results.map(template => ({
                    id: template.id,
                    name: template.name,
                    labels: template.labels,
                    reusable: template.reusable,
                    touchpointDescription: template.touchpointDescription
                }));
            }

            return null;
        },
        hasNextPage() {
            if (
                typeof this.pagedTemplates === 'object' &&
                typeof this.pagedTemplates.next === 'string'
            ) {
                return true;
            }
            return false;
        },
        selectedRowIds() {
            if (typeof this.selectedRowsMap === 'object') {
                return Object.keys(this.selectedRowsMap).filter(
                    rowId => this.selectedRowsMap[rowId]
                );
            }

            return [];
        },
        selectedRowsCount() {
            if (Array.isArray(this.selectedRowIds)) {
                return this.selectedRowIds.length;
            }

            return 0;
        },
        actionOptions() {
            const allOptions = Object.values(this.actions);

            if (this.selectedRowsCount === 1) {
                return allOptions;
            }
            if (this.selectedRowsCount > 1) {
                const newOptions = allOptions.filter(
                    option => option.value !== this.actions.EDIT.value
                );
                return newOptions;
            }

            return [];
        }
    },
    watch: {
        // update selectedRowsMap when templates change
        pagedTemplates: {
            immediate: true,
            handler: function(pagedTemplates) {
                if (typeof pagedTemplates === 'object' && Array.isArray(pagedTemplates.results)) {
                    const previousSelectedRowsMap = this.selectedRowsMap;
                    const newSelectedRowsMap = {};

                    this.pagedTemplates.results.forEach(row => {
                        newSelectedRowsMap[row.id] = previousSelectedRowsMap[row.id] || false;
                    });

                    this.selectedRowsMap = newSelectedRowsMap;
                }
            }
        },
        // clear allRowsSelected when a row in selectedRowsMap is unselected
        selectedRowsMap: {
            deep: true,
            handler: function(selectedRowsMap) {
                if (Object.values(selectedRowsMap).includes(false)) {
                    this.allRowsSelected = false;
                }
            }
        },
        filters: {
            deep: true,
            handler: function(filters) {
                this.$emit('filtersUpdated', filters);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.templates-table {
    .chips-wrapper {
        .chips-container {
            margin-bottom: 15px;
        }
    }

    .templates-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 25px;

        .filters {
            display: flex;
            align-items: center;

            .search {
                width: 27rem;
            }

            .label-filter {
                width: 200px;
                margin-left: 20px;
            }
        }

        .buttons {
            display: flex;

            * {
                margin-left: 1rem;
            }
        }
    }
    &:deep(.table-container table) {
        border-collapse: collapse;

        th,
        td {
            &:nth-of-type(1) {
                width: 1.7rem;
            }

            &:nth-of-type(2) {
                width: 25%;
            }

            &:nth-of-type(3) {
                width: auto;
            }
        }

        tbody {
            tr {
                height: 120px;
                &.row {
                    border-top: 1px solid $black-alpha-0-25;
                    &:first-of-type {
                        border-top: 0;
                    }
                    &:hover {
                        background-color: $white-blue;
                    }
                }

                td {
                    &.name {
                        .content {
                            display: flex;
                            align-items: center;
                            h3 {
                                cursor: pointer;
                            }

                            .tooltip-container {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding-left: 0.5rem;

                                .tooltip-image {
                                    width: 12px;
                                    margin-bottom: 0.5px;
                                }

                                > div {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }
                        }
                    }

                    &.labels {
                        .content {
                            display: flex;
                            flex-wrap: wrap;
                            padding: 1rem 0;
                            .chip {
                                $color: $black-alpha-0-5;
                                border-color: $color;
                                color: $color;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
